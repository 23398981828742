import clone from 'lodash/clone'
import defaultsDeep from 'lodash/defaultsDeep'
import { isReactNativeWebView } from './usePostMessageChannel'
import localStorageHelper from './localStorageHelper'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'

export type ConfigReadErrors = {
  emptyToken?: boolean
  emptyOrigin?: boolean
}

// Default atomic api – https://sandbox.api.atomicvest.com
const mode: Mode = 'sandbox'
const cluster: Cluster = 'prod'
const defaultOptions: Partial<ModuleOptions> = {
  mode,
  cluster,
  shouldCloseAfterOnboarding: true,
}

export function parseConfig(searchParams: URLSearchParams): ModuleOptions {
  return JSON.parse(decodeURIComponent(searchParams.get('config') || '{}'))
}

export function readConfig(
  config: Partial<ModuleOptions>
): [ModuleOptions, ConfigReadErrors] {
  const errors: ConfigReadErrors = {}
  if (!config.token) {
    errors.emptyToken = true
  }

  // We need origin to send messages to parent window.
  // It's required for module embedded with iframe.
  // For webview we don't need origin.
  if (!config.origin && !isReactNativeWebView()) {
    errors.emptyOrigin = true
  }

  const resolvedConfig = defaultsDeep(clone(config), defaultOptions)
  resolvedConfig.features = resolvedConfig.features ?? []

  // TODO: Remove when completely remove 'dev-i18n' support
  if (resolvedConfig.features.includes('dev-i18n')) {
    resolvedConfig.features = resolvedConfig.features.filter(
      (feature: string) => feature !== 'dev-i18n'
    )
    resolvedConfig.features.push('allow-international')
  }

  if (resolvedConfig.features.includes('dev-persona-fix-kyc')) {
    resolvedConfig.features = resolvedConfig.features.filter(
      (feature: string) => feature !== 'dev-persona-fix-kyc'
    )
    resolvedConfig.features.push('dashboard-identity-banner')
  }

  // TODO: Remove when completely remove 'customCss' support
  if (resolvedConfig.customCss) {
    resolvedConfig.custom_css = resolvedConfig.customCss
    delete resolvedConfig.customCss
  }

  if (
    isEmpty(resolvedConfig.wire_instructions) &&
    !resolvedConfig?.excluded_onboarding_pages?.includes('page-wire-details')
  ) {
    resolvedConfig?.excluded_onboarding_pages?.push('page-wire-details')
  }

  if (
    resolvedConfig?.excluded_onboarding_pages?.includes('page-connect-account')
  ) {
    resolvedConfig?.excluded_onboarding_pages?.push(
      'page-unable-to-link-account'
    )
  }

  if (!resolvedConfig.settings) {
    resolvedConfig.settings = {}
  }

  if (!resolvedConfig.settings.initial_investment_steps) {
    resolvedConfig.settings.initial_investment_steps = [100, 250, 500]
  }

  if (!resolvedConfig.settings.contribution_plan_amount) {
    resolvedConfig.settings.contribution_plan_amount = 150
  }

  if (isObject(resolvedConfig.token)) {
    if (!resolvedConfig.token.session_token) {
      throw new Error('readConfig - token.session_token is required')
    }
    if (!resolvedConfig.token.entity_type) {
      throw new Error('readConfig - token.entity_type is required')
    }
    if (!resolvedConfig.token.entity_id) {
      throw new Error('readConfig - token.entity_id is required')
    }
    if (!resolvedConfig.token.identifier) {
      throw new Error('readConfig - token.identifier is required')
    }
    if (
      resolvedConfig.view === 'corporate' &&
      resolvedConfig.token.entity_type !== 'corporate'
    ) {
      throw new Error(
        'readConfig - token.entity_type should be "corporate" when using session config as token and view="corporate"'
      )
    }
  }

  return [resolvedConfig, errors]
}

export function readConfigFromEnv() {
  const savedConfig = localStorageHelper.get('config')
  if (savedConfig) {
    localStorageHelper.clear('config')
    return [savedConfig, {}]
  }
  const searchParams = new URLSearchParams(window.location.search)
  const parsedConfig = parseConfig(searchParams)
  const [resolvedConfig, errors] = readConfig(parsedConfig)
  return [resolvedConfig, errors, parsedConfig]
}

const urlToAtomicEnv = new Map<string, AtomicEnv>([
  ['https://api.atomicvest.com', 'prod-live'],
  ['https://sandbox.api.atomicvest.com', 'prod-sandbox'],
  ['https://api-live.qa.atomicvest.com', 'qa-live'],
  ['https://api-sandbox.qa.atomicvest.com', 'qa-sandbox'],
  ['https://api-sandbox.staging.atomicvest.com', 'staging-sandbox'],
])

const modeClusterToAtomicEnv = new Map<string, AtomicEnv>([
  ['prod:production', 'prod-live'],
  ['prod:sandbox', 'prod-sandbox'],
  ['qa:api-live', 'qa-live'],
  ['qa:api-sandbox', 'qa-sandbox'],
  ['staging:api-sandbox', 'staging-sandbox'],
])

export function makeAtomicEnv(options: ModuleOptions) {
  if (options._override_api) {
    return urlToAtomicEnv.get(options._override_api)
  } else {
    const cluster = options.cluster
    const mode = options.mode
    return modeClusterToAtomicEnv.get([cluster, mode].join(':'))
  }
}
